import Vue from 'vue'
import App from './App.vue'
// 関連Libインポート
import vuetify from './plugins/vuetify'
import veeValidate from "./plugins/vee-validate"
import vueLoading from "./plugins/vue-loading"
import vuejsDatepicker from "./plugins/vuejs-datepicker"
// import vueSelectSides from "./plugins/vue-select-sides"
import router from './router'
import store from './store'
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFunctions } from "firebase/functions";
import scrollTo from 'vue-scrollto';

Vue.config.productionTip = false

// firebase初期設定
// TODO 環境により変更が必要なため一生残しておくTODO
const firebaseConfig = {
  apiKey: "AIzaSyD3ehl0Jy5vtfZqRJst0YfZeRfPlp4WP3Y",
  authDomain: "schoolaid-home-kamajo-test.firebaseapp.com",
  projectId: "schoolaid-home-kamajo-test",
  storageBucket: "schoolaid-home-kamajo-test.appspot.com",
  messagingSenderId: "521545540129",
  appId: "1:521545540129:web:52746f6b31c1607b803960"
};
const version = 'ver 2.1.004';
const copyright = '©Canon Electronics Technology Inc.';

const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const functions = getFunctions(firebase);
functions.region = "asia-northeast1";

// validateを各種画面にて使用できるようにする
Vue.mixin(veeValidate)
Vue.mixin(vueLoading)
Vue.mixin(vuejsDatepicker)
Vue.config.devtools = true;
Vue.use(scrollTo);

new Vue({
  data: {
    isLogin: false,
    loading: false,
    siteName: "",
    version: version,
    copyright: copyright,
  },
  vuetify,
  router,
  store,
  firebase,
  functions,
  analytics,
  render: h => h(App)
}).$mount('#app')
